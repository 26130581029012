// const ListAllAssets = `query ListAllAssets($coId: String!, $start: Int, $limit: Int) {
//   listAllAssetsWithMeta(coId: $coId, start: $start, limit: $limit) {
//     id
//     fileName
//     fileInfo
//     fileSize
//     assetType
//     highRezFileName
//     companyId
//     name
//     setDate
//     realSetId
//     assetId
//     headline
//     caption
//     keywords
//     byline
//     suppCat
//     city
//     video_length
//     category
//   }
// }
// `
export const ListAllAssets = `query ListAllAssets($coId: String!, $searchStr: String, $searchType: String!, $start: Int, $limit: Int) {
  listAllAssetsFTSearch(coId: $coId, searchStr: $searchStr, searchType: $searchType, start: $start, limit: $limit) {
    id
    fileName
    fileInfo
    fileSize
    assetType
    highRezFileName
    companyId
    name
    setDate
    realSetId
    assetId
    headline
    caption
    keywords
    byline
    suppCat
    city
    video_length
    category
  }
}
`;

export const ListShotwireAssetsDeltas = `query ListAllshotwireAssetsDeltas($filter: TableShotwireAssetsDeltaFilterInput, $limit: Int, $nextToken: String){
      listShotwireAssetsDeltas(filter: $filter, limit: $limit, nextToken: $nextToken){
        items {
          id
          fileName
          fileInfo
          fileSize
          assetType
          highRezFileName
          companyId
          name
          setDate
          realSetId
          assetId
          headline
          caption
          keywords
          byline
          suppCat
          city
          video_length
          archived
          category
          thumbNailUrl
          mp4Urls
          dashUrl
          hlsUrl
          cmafDashUrl
          cmafHlsUrl
        }
        nextToken
        
      }
    }`;
    

 export   const ListShotAssetsDeltasIndex = `query ListSAssetsDeltasIndex($category: String!,$filter: TableShotwireAssetsDeltaFilterInput, $limit: Int, $nextToken: String, $scannedCount: Int){
      listShotAssetsDeltasIndex(category: $category, filter: $filter ,limit: $limit, nextToken: $nextToken, scannedCount: $scannedCount){
        items {
          id
          category
          setDate 
          name
          fileName
          fileInfo
          fileSize
          assetType
          highRezFileName
          companyId
          realSetId
          assetId
          headline
          caption
          keywords
          byline
          suppCat
          city
          video_length
          archived
          category
          thumbNailUrl
          mp4Urls
          dashUrl
          hlsUrl

        }
        nextToken
        scannedCount
      }
    }`;

   export const ListShotAssetsDateDeltasIndex = `query ListSAssetsDeltasIndex($category: String!,$filter: TableShotwireAssetsDeltaFilterInput, $limit: Int, $nextToken: String, $scannedCount: Int){
      listShotAssetsDateDeltasIndex(category: $category, filter: $filter ,limit: $limit, nextToken: $nextToken, scannedCount: $scannedCount){
        items {
          id
          category
          setDate 
          name
          fileName
          fileInfo
          fileSize
          assetType
          highRezFileName
          companyId
          realSetId
          assetId
          headline
          caption
          keywords
          byline
          suppCat
          city
          video_length
          archived
          category
          thumbNailUrl
          mp4Urls
          dashUrl
          hlsUrl
        }
        nextToken
        scannedCount
      }
    }`;

    export const getShotwireAssetsDeltaPublic = /* GraphQL */ `
  query GetShotwireAssetsDelta($id: ID!) {
    getShotwireAssetsDelta(id: $id) {
      id
      assetId
      assetType
      fileName
      fileInfo
      fileSize
      name
      realSetId
      setDate
      companyId
      caption
      headline
      instructions
      objectName
      source
      copyright
      keywords
      byline
      fixtureId
      suppCat
      captionWriter
      city
      state
      country
      countryCode
      originalTransmissionReference
      releaseDate
      createdDate
      modified
      video_format
      video_bitrate
      video_width
      video_height
      video_fps
      video_aspect
      audio_codec
      audio_format
      audio_bitrate
      audio_rate
      audio_nch
      length
      video_frames
      video_length
      audio_length
      archived
      proxyInfo
      apiSource
      thumbNailUrl
      hlsUrl
      dashUrl
      workflowStatus
      category
      searches
      uploader
      timestamp
      license
      marketplace
      srcMediainfo
      owner
      cmafDashUrl
      cmafHlsUrl
    }
  }
`;
export const listShotwireAssetsDeltasPublic = /* GraphQL */ `
  query ListShotwireAssetsDeltas(
    $filter: ModelShotwireAssetsDeltaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShotwireAssetsDeltas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetId
        assetType
        fileName
        fileInfo
        fileSize
        name
        realSetId
        setDate
        companyId
        caption
        headline
        instructions
        objectName
        source
        copyright
        keywords
        byline
        fixtureId
        suppCat
        captionWriter
        city
        state
        country
        countryCode
        originalTransmissionReference
        releaseDate
        createdDate
        modified
        video_format
        video_bitrate
        video_width
        video_height
        video_fps
        video_aspect
        audio_codec
        audio_format
        audio_bitrate
        audio_rate
        audio_nch
        length
        video_frames
        video_length
        audio_length
        archived
        s3srcUrl
        proxyInfo
        apiSource
        thumbNailUrl
        mp4Urls
        hlsUrl
        dashUrl
        workflowStatus
        category
        searches
        uploader
        timestamp
        license
        marketplace
        srcMediainfo
        owner
        cmafHlsUrl
        cmafDashUrl
      }
      nextToken
    }
  }
`;
export const listAssetsByCategoryPublic = /* GraphQL */ `
  query ListAssetsByCategory(
    $category: String
    $setDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShotwireAssetsDeltaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetsByCategory(
      category: $category
      setDate: $setDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetId
        assetType
        fileName
        fileInfo
        fileSize
        name
        realSetId
        setDate
        companyId
        caption
        headline
        instructions
        objectName
        source
        copyright
        keywords
        byline
        fixtureId
        suppCat
        captionWriter
        city
        state
        country
        countryCode
        originalTransmissionReference
        releaseDate
        createdDate
        modified
        video_format
        video_bitrate
        video_width
        video_height
        video_fps
        video_aspect
        audio_codec
        audio_format
        audio_bitrate
        audio_rate
        audio_nch
        length
        video_frames
        video_length
        audio_length
        archived
        proxyInfo
        apiSource
        thumbNailUrl
        mp4Urls
        hlsUrl
        dashUrl
        workflowStatus
        category
        searches
        uploader
        timestamp
        license
        marketplace
        srcMediainfo
        owner
        cmafHlsUrl
        cmafDashUrl
      }
      nextToken
    }
  }
`;



